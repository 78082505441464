<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-careplan-view">

    <vs-alert color="danger" title="Care Plan Not Found" :active.sync="carePlan_not_found">
      <span>Care Plan record with id: {{ $route.params.carePlanId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'all-activity-list'}" class="text-inherit underline">All Care Plans</router-link>
      </span>
    </vs-alert>

    <div id="careplan-data" v-if="carepPlan_data">
{{ carepPlan_data.packageId }}
      <vx-card title="Care Plan" class="mb-base">

        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img src="https://healthminder-resources.s3-ap-southeast-1.amazonaws.com/img/profile-listing/staff1%403x.png" class="rounded w-full" />
            </div>
          </div>


          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <!-- tr>
                <td class="font-semibold">Username</td>
                <td>{{ carepPlan_data.username }}</td>
              </tr -->
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ carepPlan_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ carepPlan_data.ageGroup }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ carepPlan_data.status }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Role</td>
                <td>{{ carepPlan_data.acceptingNewPatient }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Company</td>
                <td>{{ carepPlan_data.bookOnline }}</td>
              </tr>
             
                            <tr>
                <td class="font-semibold">View Pattern</td>
                <td>  
                <router-link :to="{ name: 'app-resource-pattern-view', params: {resourceId: $route.params.carePlanId  }}">View</router-link> </td>
              </tr>
              
Name
Gender
Date of Birth
Email
Password
Address
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'app-user-edit', params: { resourceId: $route.params.resourceId }}">Edit</vs-button>
            <vs-button type="border" color="danger" icon-pack="feather" icon="icon-trash" @click="confirmDeleteRecord">Delete</vs-button>
          </div>

        </div>

      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Information" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Birth Date</td>
                <td>{{ carepPlan_data.dob }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Mobile</td>
                <td>{{ carepPlan_data.mobile }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Website</td>
                <td>{{ carepPlan_data.website }}</td>
              </tr>
              <!-- tr>
                <td class="font-semibold">Languages</td>
                <td>{{ carepPlan_data.languages_known.join(", ") }}</td>
              </tr -->
              <tr>
                <td class="font-semibold">Gender</td>
                <td>{{ carepPlan_data.gender }}</td>
              </tr>
              <!--tr>
                <td class="font-semibold">Contact</td>
                <td>{{ carepPlan_data.contact_options.join(", ") }}</td>
              </tr -->
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Insurance" class="mb-base">
            <!-- table>
              <tr>
                <td class="font-semibold">National Insurance</td>
                <td>{{ carepPlan_data.social_links.twitter }}</td>
              </tr>
              <tr>
                <td class="font-semibold"></td>
                <td>{{ carepPlan_data.social_links.facebook }}</td>
              </tr>
              <tr>
                <td class="font-semibold"></td>
                <td>{{ carepPlan_data.social_links.instagram }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Private Insurance</td>
                <td>{{ carepPlan_data.social_links.github }}</td>
              </tr>
              <tr>
                <td class="font-semibold"></td>
                <td>{{ carepPlan_data.social_links.codepen }}</td>
              </tr>
              <tr>
                <td class="font-semibold"></td>
                <td>{{ carepPlan_data.social_links.slack }}</td>
              </tr>
            </table -->
          </vx-card>
        </div>
      </div>

      <!-- Permissions -->
      <vx-card>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" class="mr-2" />
              <span class="font-medium text-lg leading-none">Services</span>
            </div>
            <vs-divider />
          </div>
        </div>

        <div class="block overflow-x-auto">
          <table class="w-full permissions-table">
            <tr>
              <!--
                You can also use `Object.keys(Object.values(data_local.permissions)[0])` this logic if you consider,
                our data structure. You just have to loop over above variable to get table headers.
                Below we made it simple. So, everyone can understand.
               -->
              <th class="font-semibold text-base text-left px-3 py-2" v-for="heading in ['Module', 'Read',  'Read', 'Write', 'Create', 'Delete']" :key="heading">{{ heading }}</th>
            </tr>

            <tr v-for="(val, name) in carepPlan_data.serviceRequests" :key="name">
              <td class="px-3 py-2">{{ val.name }}</td>
               <td class="px-3 py-2">{{ val.category }}</td>
                <td class="px-3 py-2">{{ val.note }}</td>
                 <td class="px-3 py-2">{{ val.cost }}</td>
                 <td class="px-3 py-2">{{ val.locationCode}}</td>
                 <td class="px-3 py-2">{{ val.status }}</td>

            </tr>
          </table>
        </div>

      </vx-card>
    </div>
  </div>
</template>

<script>

import moduleCarePlan from '@/store/careplan/moduleCarePlan.js'

export default {
  data() {
    return {
      carepPlan_data: null,
      carePlan_not_found: false,
    }
  },
  computed: {

  },
  created() {
    // Register Module UserManagement Module
    if(!moduleCarePlan.isRegistered) {
      this.$store.registerModule('moduleCarePlan', moduleCarePlan)
      moduleCarePlan.isRegistered = true
    }

    const carePlanId = this.$route.params.carePlanId;

    console.log(carePlanId);
   
    const payload = {

        id: carePlanId
      }
console.log("Payload");
      console.log(carePlanId);
  this.$store.dispatch("moduleCarePlan/fetchCarePlanById", payload)
      .then(res => { 
        console.log(res);
        this.carepPlan_data = res.data })
      .catch(err => {
        if(err.response.status === 404) {
          this.carePlan_not_found = true
          return
        }

                if(err.response.status === 500) {
          this.carePlan_not_found = true
          return
        }
        console.error(err) })
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-activity-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
